<template>
    <v-container>

        <v-row class="my-3">
            <v-col cols="12">
                <div class="title-section">
                    CREAR CLIENTE
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <kimsa-text-field
                    label="Nombre del Cliente"
                    outlined solo
                    flat dense
                    @change="name = $event"
                />
            </v-col>
            <v-col cols="12" md="3">
                <v-btn @click="saveClient"
                       block depressed
                       color="primary"
                       :disabled="!valid"
                       :loading="loading"
                        class="normal-btn">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Cliente
                </v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import Axios from "@/utils/Axios";

export default {
    name: "ClientCreate",
    components: {KimsaTextField},
    data() {
        return {
            loading: false,
            name: '',
        }
    },
    computed: {
        valid(vm = this) {
            return !!vm.name
        }
    },
    methods: {
        async saveClient() {
            const vm = this
            vm.loading = true
            let payload = {name: vm.name}

            await Axios.post('client/new', payload).then(res => {
                console.log('res saveClient',res)
                vm.toast('Cliente creado')
                vm.$router.go(-1)
            }).catch(error => {
                console.log('error saveClient', error)
            }).finally(() => {
                vm.loading = false
            })
        }
    },
}
</script>

<style scoped>

.normal-btn {
    text-transform: none;
}

</style>